import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { ChurchComponent } from './church/church.component'
import { SearchresultComponent } from './searchresult/searchresult.component'
import { StartComponent } from './start/start.component'
import { ShellComponent } from './shell/shell.component'
import { WebComponent } from './web/web.component'

const routes: Routes = [
    {
        path: '',
        component: ShellComponent,
        children: [
            { path: '', component: StartComponent },
            { path: 'web/:site', component: WebComponent },
            { path: 'searchresult', component: SearchresultComponent },
            { path: 'searchresult/geo', component: SearchresultComponent }
        ]
    },
    {
        path: 'church/:churchId',
        component: ChurchComponent
    },
    {
        path: 'church/:churchId/event/:site',
        component: WebComponent
    },
    {
        path: 'church/:churchId/news/:site',
        component: WebComponent
    },
    {
        path: '**',
        component: StartComponent
    },
    {
        path: 'x**',
        redirectTo: '/',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
